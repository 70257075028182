<script setup lang="ts">
import type { MenuItem } from '~~/types/menu/MenuItem'

defineComponent({
  name: 'NavigationComponent'
})

const properties = defineProps({
  items: {
    type: Array as () => MenuItem[],
    required: true
  },
  active: {
    type: String,
    default: ''
  }
})
</script>

<template>
  <template v-for="item of properties.items" :key="item.title">
    <NuxtLink v-if="item.visible" :to="item.href" class="ml-8 menu-links">
      <v-badge v-if="item.badge" :content="item.badge" color="accent">
        <span :class="[item.href === properties.active ? 'text-primary' : '', 'mr-2']">{{
          item.title
        }}</span>
      </v-badge>
      <span v-else :class="item.href === properties.active ? 'text-primary' : ''">{{
        item.title
      }}</span>
    </NuxtLink>
  </template>
  <NuxtLink to="/kontakt" class="ml-8 cta">
    <span>Kontakt</span>
  </NuxtLink>
</template>

<style scoped lang="scss">
.cta {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: rgba(var(--v-theme-primary), 1);
  color: #fff;
  font-weight: 500;
  transition: background-color 0.3s ease;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(var(--v-theme-primary), 0.8);
  }
}

.menu-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: 500;
  color: rgba(var(--v-theme-textSecondary), 1);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: rgba(var(--v-theme-primary), 1);
    text-decoration: underline;
    text-decoration-color: rgb(var(--v-theme-accent));
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
  }
}
</style>
