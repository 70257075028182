<script setup lang="ts">
import type { MenuItem } from '~~/types/menu/MenuItem'

const properties = defineProps({
  primary: {
    type: Boolean,
    default: false
  }
})

const mobileMenu = ref(false)
const headerHeight = ref(160)
const elevation = ref(0)

const route = useRoute()

const menuItems: MenuItem[] = [
  {
    title: 'Leistungen',
    href: '/leistungen',
    visible: true
  },
  {
    title: 'Lösungen',
    href: '/loesungen',
    visible: true
  },
  {
    title: 'Referenzen',
    href: '/referenzen',
    visible: false
  },
  {
    title: 'Über uns',
    href: '/ueber-uns',
    visible: true
  },
  {
    title: 'Karriere',
    href: '/karriere',
    visible: true
  }
]

defineComponent({
  name: 'HeaderComponent'
})

onMounted(() => {
  window.addEventListener('scroll', () => {
    headerHeight.value = window.scrollY <= 80 ? 160 : 80
    elevation.value = window.scrollY <= 80 ? 0 : 10
  })
})
</script>
<template>
  <div>
    <div :style="`margin-bottom: ${headerHeight + 1}px`">
      <v-app-bar
        :elevation="elevation"
        :height="headerHeight"
        class="lp-header"
        :class="properties.primary ? 'bg-primary' : ''"
        flat
      >
        <v-container class="maxWidth py-sm-4 py-0">
          <v-toolbar
            class="d-flex align-center"
            :class="properties.primary ? 'bg-primary' : 'bg-white'"
          >
            <div>
              <Logo :color="properties.primary ? '#fff' : '#000'" :height="30" :width="150" />
            </div>
            <div class="ml-auto d-lg-flex d-none mr-2">
              <Navigation :items="menuItems" :active="route.path" />
            </div>
            <div class="hidden-lg-and-up ml-auto mr-4">
              <div
                class="menu-wrapper"
                :class="{ active: mobileMenu }"
                @click.stop="mobileMenu = !mobileMenu"
              >
                <div
                  class="menu-bar one"
                  :class="properties.primary ? 'bg-white' : 'bg-black'"
                ></div>
                <div
                  class="menu-bar two"
                  :class="properties.primary ? 'bg-white' : 'bg-black'"
                ></div>
                <div
                  class="menu-bar three"
                  :class="properties.primary ? 'bg-white' : 'bg-black'"
                ></div>
              </div>
            </div>
          </v-toolbar>
        </v-container>
      </v-app-bar>
      <div v-if="mobileMenu" class="mobile-menu-container" :style="`margin-top:${headerHeight}px;`">
        <template v-for="item of menuItems" :key="item.title">
          <div
            v-if="item.visible"
            class="mobile-menu-items-container"
            :class="properties.primary ? 'bg-primary' : 'bg-white'"
          >
            <v-badge v-if="item.badge" :content="item.badge" color="accent">
              <a
                :href="item.href"
                class="mobile-menu-item text-body text-h5 mb-6 ml-2 mr-2"
                :class="{ 'text-primary': item.href === route.path }"
              >
                {{ item.title }}
              </a>
            </v-badge>
            <a
              v-else
              :href="item.href"
              class="mobile-menu-item text-body text-h5 mb-6"
              :class="{
                'text-accent': item.href === route.path,
                'text-white': properties.primary,
                'text-black': !properties.primary
              }"
            >
              {{ item.title }}
            </a>
          </div>
        </template>
        <div
          class="mobile-menu-items-container"
          :class="properties.primary ? 'bg-primary' : 'bg-white'"
        >
          <div
            class="py-2 px-4 text-white rounded-md mb-4"
            :class="properties.primary ? 'bg-white' : 'bg-primary'"
          >
            <a
              href="/kontakt"
              class="mobile-menu-item text-body text-h5 mb-6"
              :class="properties.primary ? 'text-primary' : 'text-white'"
            >
              Kontakt
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-white {
  background-color: #fff;
}

.bg-black {
  background-color: #000;
}

.mobile-menu-container {
  position: fixed;
  z-index: 1000;
}

.mobile-menu-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: #fff;
  width: 100vw;
}

.mobile-menu-item {
  text-align: center;
  color: #000;
  text-decoration: none;
  transition: all 0.3s;
}

.menu-wrapper {
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
}

.menu-bar {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0%;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.menu-wrapper:hover .menu-bar.active {
  animation: none;
}
.active .one {
  top: 50%;
  left: 0%;
  transform: rotate(45deg);
}

.active .two {
  opacity: 0;
  transition: all 0.3s;
}

.active .three {
  top: 50%;
  left: 0%;
  transform: rotate(-45deg);
}

.one {
  top: 5px;
  animation-delay: 0.1s;
  transition: all 0.3s;
}

.two {
  top: 12px;
  opacity: 1;
  transition: all 0.3s;
}

.three {
  top: 19px;
  transition: all 0.3s;
}
</style>
